import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs-compat';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlotsService {

  constructor(private http: HttpClient) { }

  setNSDQValues(): Observable<Array<any>> {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    const result: Subject<Array<any>> = new Subject<Array<any>>();
    this.http.get<any>('https://api.backseat-trader.com/rest/nsdqchart', { headers }).subscribe((data: any) => {
      // Your parsing code...
      // result.next(event);
      // result.complete();
      // console.log(data);
      let res: any = "https://api.backseat-trader.com/media/" + data[0].img[0];
      result.next(res);
    	result.complete();
    });
    return result;
  }
}
