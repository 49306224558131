import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './providers/config/config.service';
import { map, first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MultimediaserviceService {

  // API url

  constructor(private http: HttpClient, private config: ConfigService) { }

  // Returns an observable
  role;

  setRole(role){
    this.role = role;
  }

  getRole(){
    return this.role;
  }

  makeAdmin(): Observable<any>{
    let obj = {
      "email": "jfrancis@ait.ie",
      "password": "cosmicAdmin113!",
      "role": "admin"
    }

    return this.http.post<any>(this.config.url + 'user/register', obj).pipe(map(user => {

    }));
  }
  theData;
  upload(title, body, file: Object): Observable<any> {

    try {
      // console.log("upload()", file);

      let obj2 = {
        title: title,
        body: body,
        //baseType: file.type,
        //baseSize: file.size,
        file: file
      }




      let obj = { title: title, body: body, base: file }

      // console.log(this.config.url + 'newsletter')
      return this.http.post<any>(this.config.url + 'newsletter', {title: title, body: body, file: file})
        .pipe(map(user => {
        }));
    } catch (exception) {
      // console.log("failed");
      // console.log(exception);
    }

  }
}
