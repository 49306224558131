import { Component, OnInit, HostListener } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigService } from './providers/config/config.service';

declare let gtag: Function;
// declare var filter: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Backseat Trader';

  constructor(private wowService: NgwWowService, private router: Router) {
    this.wowService.init();
    // console.log(router.url)
    
  }
  
  ngOnInit() {
this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
      /** END */
    })

    // console.log(window.location.href)
    let url = window.location.href;
    let local = "localhost";
    let live = "backseat-trader";
    let ie = ".ie"; 

    if(url.includes(local)){
      // console.log(true, "local");
    }else if(url.includes(live)){
      // console.log(true, "live");
      if(url.includes(ie)){
        window.open("https://backseat-trader.com","_self")
      }
    }
  }

  @HostListener("window:onbeforeunload",["$event"])
  clearLocalStorage(event){
      sessionStorage.removeItem("windowPos");
  }

}
