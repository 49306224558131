import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-index-video-content',
    template: `
    <div class="modal-body" style="text-align: center;"> 
        <div class="row" style="height: 100%">
            <div class="col" style="height: 100%">
                <div class="video-container" style="height: 100%">
                    <iframe src="https://theapi.backseat-trader.com/Introducing-eToro.mp4" width="1000"
					height="300" allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    </div>
    `,
    styleUrls: ['./index-video.component.css']
})
export class IndexVideoContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) { }
}

@Component({
    selector: 'app-index-video-component',
    templateUrl: './index-video.component.html',
    styleUrls: ['./index-video.component.css']

})
export class IndexVideoComponent {
    constructor(private modalService: NgbModal) { }
    open() {
        const modalRef = this.modalService.open(IndexVideoContent, { windowClass: 'largeModal' });
        modalRef.componentInstance.name = 'World';
    }
}
