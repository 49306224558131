import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
  import { Meta } from '@angular/platform-browser';
  import { Location } from '@angular/common';
  import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-seo11',
  templateUrl: './seo11.component.html',
  styleUrls: ['./seo11.component.css']
})
export class Seo11Component implements OnInit, AfterViewInit {

  constructor(private meta: Meta,  private _location: Location, private nav: NavbarService) { }
  mobile: Boolean = false;
  ngOnInit(): void {
    this.meta.addTags([
      {name: 'title', content: "Creating a Nasdaq-100 Trading Algorithm: A Step-by-Step Guide"},
      {name: 'description', content: "How to develop an effective Nasdaq-100 trading algorithm with a systematic approach covering data collection, feature engineering, model selection, backtesting, optimisation, and more."}
    ]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.nav.hideSet(true);
  }

  backClicked() {
    this._location.back();
  }

  ngAfterViewInit(): void {

    let winPosSEO = sessionStorage.getItem("windowPosSubSEO");
    if(winPosSEO){
      // console.log("found!")
      let numWinPosSEO = Number(winPosSEO);
      // window.location.replace('http://localhost:4200/');
      if(numWinPosSEO > 0){
        // console.log("scrolling!", numWinPosBubbles)
        window.scrollTo(0, numWinPosSEO);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPosSEO){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPosSEO)
        }
      }
    }
  }

  capturePos(){
    // console.log(window.scrollY, "scrollY")
    // console.log(window.screenY, "screenY")
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString())
  }

  @HostListener("window:beforeunload", ["$event"])
  refreshDetected(){
    // console.log("refreshed!");
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString());
  }
  
  removePos(){
    sessionStorage.removeItem("windowPos");
  }


}
