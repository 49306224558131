import { Component, OnInit } from '@angular/core';
import { AnonymousSubject } from 'rxjs-compat';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SigninserviceService } from '../signinservice.service';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus=true;
  focus1=true;

  invalid;

 
  hide;
  submitted;
  showError;
  loading;

  loginform: FormGroup;

  email : string;
  password : string;

  constructor(private signInServ : SigninserviceService, private route : Router, private formBuilder : FormBuilder,  public nav: NavbarService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: User Login'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);

    this.loginform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(6), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // window.history.replaceState(null, null, "/login");
    this.nav.hideSet(true);
    this.hide = true;
  }

  get f() { return this.loginform.controls; }



  async signIn(){
    this.email = this.f.email.value;
    this.password = this.f.password.value;
    //console.log(this.email);
    //console.log(this.password);
    this.signInServ.setParams(this.email, this.password).subscribe(
      response => {
        // console.log(response);
        let userId = response.user._id;
        // console.log(userId)
        this.route.navigate(['/user']);
        this.invalid = "";
        const jsonData = JSON.stringify(response.user.username)
        const username = eval(jsonData);
        localStorage.setItem('username', username);
        localStorage.setItem('userIdCosmic', userId);
      }
    );
    this.invalid = "Invalid login details"
  }
  

}
