import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-risk-infomartion',
  templateUrl: './risk-infomartion.component.html',
  styleUrls: ['./risk-infomartion.component.css']
})
export class RiskInfomartionComponent implements OnInit, AfterViewInit {


  mobile: Boolean = false;

  constructor(private route: Router, private meta: Meta, private _location: Location, private nav: NavbarService) { }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.nav.hideSet(true);
  }

  ngAfterViewInit(): void {

    let winPosSEO = sessionStorage.getItem("windowPosSubSEO");
    if(winPosSEO){
      // console.log("found!")
      let numWinPosSEO = Number(winPosSEO);
      // window.location.replace('http://localhost:4200/');
      if(numWinPosSEO > 0){
        // console.log("scrolling!", numWinPosBubbles)
        window.scrollTo(0, numWinPosSEO);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPosSEO){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPosSEO)
        }
      }
    }
  }

  capturePos(){
    // console.log(window.scrollY, "scrollY")
    // console.log(window.screenY, "screenY")
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString())
  }

  @HostListener("window:beforeunload", ["$event"])
  refreshDetected(){
    // console.log("refreshed!");
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString());
  }

}
