import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-global-news',
  templateUrl: './global-news.component.html',
  styleUrls: ['./global-news.component.css']
})
export class GlobalNewsComponent implements OnInit {

  constructor(private http: HttpClient) { }

  mobile: Boolean = false;

  async ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    await this.getMonthlyGains();
    await this.getBenchmark();
  }

  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];
  stampForGraph: any;
  tGain;
  tLaunch;
  gPos = false;
  lPos = false;

  updateRecent: any;

  public options: any = {

    // chart: {
    //   type: 'column'
    // },
    // title: {
    //   text: 'Our Monthly Gains to Date'
    // },

    // xAxis: {
    //   categories: ['Green', 'Pink']
    // },

    // series: [{
    //   data: [{
    //     name: 'Point 1',
    //     color: '#00FF00',
    //     y: 1
    //   }, {
    //     name: 'Point 2',
    //     color: '#FF00FF',
    //     y: 5
    //   }]
    // }],



  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/cosmicetoro/Gain?', { headers }).subscribe(data => {
      // console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.stampForGraph = this.timestampMonthly[this.timestampMonthly.length - 1];
      this.updateRecent = this.timestampMonthly[this.timestampMonthly.length - 1]
      // this.getTheDate(this.timestampMonthly);
      // console.log(this.timestampMonthly);
      // console.log(this.stampForGraph);
      // this.populateGraphWithDynamicValues();
      // this.monthlyGain = [];
      // this.parsedMonthly = [];
      // this.gainsMonthly = [];
      // this.timestampMonthly = [];
    })
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }

  NSDQ100Arr;
  NSDQ100ArrFiltered = [];
  performanceArr;
  performanceArrFiltered = [];

  nPosBench: boolean = false;
  nBenchVal;

  ePosBench: boolean = false;
  eBenchVal;

  async getBenchmark() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/benchmark-values', { headers }).subscribe(data => {
      // console.log(data)
      this.NSDQ100Arr = data[0].NSDQ100;
      this.performanceArr = data[0].Performance;

      // console.log(this.NSDQ100Arr)
      // console.log(this.performanceArr)

      this.NSDQ100ArrFiltered = this.NSDQ100Arr.split(",");
      this.performanceArrFiltered = this.performanceArr.split(",");
      
      for(let i = 0; i <= this.performanceArrFiltered.length - 1; i++){
        this.performanceArrFiltered[i] = Number(this.performanceArrFiltered[i])
      }

      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
        this.NSDQ100ArrFiltered[i] = Number(this.NSDQ100ArrFiltered[i]);
      }

      // console.log(this.gainsMonthly[this.gainsMonthly.length - 2])
      // console.log(this.NSDQ100ArrFiltered[this.NSDQ100ArrFiltered.length - 1])

      // this.nBenchVal = this.NSDQ100ArrFiltered[this.NSDQ100ArrFiltered.length - 1];
      // this.eBenchVal = this.gainsMonthly[this.gainsMonthly.length - 2];
      let nsdqSum = 0;
      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
       nsdqSum += this.NSDQ100ArrFiltered[i];
      }
      this.nBenchVal = nsdqSum;

      let etoroSum = 0;
      for(let i = 0; i<= this.gainsMonthly.length - 2; i++){
        // console.log(this.gainsMonthly[i])
        etoroSum += this.gainsMonthly[i]
      }

      this.eBenchVal = etoroSum;


      if(this.nBenchVal >= 10){
        this.nBenchVal = this.nBenchVal.toFixed(1);
      }else{
        this.nBenchVal = this.nBenchVal.toFixed(2);
      }

      if(this.eBenchVal >= 10){
        this.eBenchVal = this.eBenchVal.toFixed(1);
      }else{
        this.eBenchVal = this.eBenchVal.toFixed(2);
      }


      if (this.nBenchVal < 0) {
        this.nPosBench = false;
        document.getElementById("tNSDQBench").className = "legendRed";
      } else {
        this.nPosBench = true;
        document.getElementById("tNSDQBench").className = "legendGreen";
      }

      if (this.eBenchVal < 0) {
        this.ePosBench = false;
        document.getElementById("eBenchID").className = "legendRed";
      } else {
        this.ePosBench = true;
        document.getElementById("eBenchID").className = "legendGreen";
      }

      this.populateBenchmark();

    })

  }

  benchmarkDate = new Date;
  benchmarkDateStr;

  populateBenchmark() {

    this.benchmarkDate.setDate(1); // going to 1st of the month
    this.benchmarkDate.setHours(-1); // going to last hour before this date even started.

    var dobArr = this.benchmarkDate.toDateString().split(' ');
    this.benchmarkDateStr = dobArr[2] + ' ' + dobArr[1] + ' ' + dobArr[3];

    var dateArr = [];
    var date;

    let months = this.timestampMonthly;
    let etoro = this.gainsMonthly;
    etoro.pop();
    months.pop();

    let max = 0;
    let maxEtoro = 0;

    let min = 0;

    let combined = [];

    for(let i = 0; i<= this.performanceArrFiltered.length - 1; i++){
      if(this.performanceArrFiltered[i] > max){
        max = this.performanceArrFiltered[i];
      }
    }

    max = Math.round(max / 10) * 10;

    for(let i = 0; i<= etoro.length - 1; i++){
      combined.push(etoro[i])
      // console.log(etoro[i])
      if(etoro[i] > maxEtoro){
        maxEtoro = etoro[i]
      }
    }

    for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
      combined.push(this.NSDQ100ArrFiltered[i]);
    }

    // console.log(combined)

    const negativeInts = combined.filter(i => i < 0)

    min = Math.min(...negativeInts)

    min = min * -1

    min = Math.ceil(min / 5) * 5;

    min = -Math.abs(min)
    // console.log(min)


    for (var i = 0; i <= months.length; i++) {
      // console.log(true)
        
        // if(i % 4 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          date = new Date(months[i]);
          dateArr.push(this.convertDate(date))
        // }else{
        //   dateArr.push("")

        // }

      
    }

    let firstDate = dateArr.shift();

    let start = 0;
    let end = dateArr.length - 2;
    let mid = Math.round((start+end)/2);

    let initialise = etoro[0];

    let plot = [];
    initialise = (initialise * 12 / 1);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= etoro.length - 1; i++) {
      if (i == 1) {
        buff.push(etoro[i] + etoro[i - 1]);
      } else {
        buff.push(etoro[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = etoro[0];

    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    this.tGain = buff[7];
    let x = this.tGain.toPrecision(3);
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    let avg = sum / buff.length - 1;

    let agr = sum * 12 / 8;

    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      splineWidth = '3';
      gridOrNot = 0;
    } else {
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }

    // console.log(etoro)



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#01B150',
              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis

        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis
        visible: false,
        // title: {
        //   text: 'Monthly Return - (Target > 1.25%)',
        //   style: {
        //     color: Highcharts.getOptions().colors[2],
        //   }
        // },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        gridLineWidth: gridOrNot,
        linkedTo: 2,
        max: maxEtoro,
      }, {
        title: {
          text: ''
        },
        gridLineWidth: 1,
        max: max,
        // min: -20,
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: '{value}%',
          style: {
            color: "black"
          }
        },
        opposite: false,
      }],

      title: {
        text: '',
      },
      plotOptions: {
        spline: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'eToro',
        data: etoro,
        yAxis: 1,
        color: "#FEDF7E"
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Performance +/-',
        color: "#01B150",
        data: this.performanceArrFiltered,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,
        }
      },{
        name: 'NSDQ100',
        data: this.NSDQ100ArrFiltered,
        yAxis: 1,
        color: "#7ED7F6"
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    Highcharts.chart('benchmarkContainer', this.options);

    
  }

  

}
