import { Component, OnInit, ViewChild } from '@angular/core';
//import { ConfigService } from 'src/app/providers/config/config.service';
import { map, first } from "rxjs/operators";
import { Router } from '@angular/router';

import { HttpClient, HttpParams } from '@angular/common/http';
import { textChangeRangeIsUnchanged } from 'typescript';
import { RegisterserviceService } from '../registerservice.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  focus=true;
  focus1=true;
  focus2=true;

  registerform: FormGroup;

  hide;
  submitted;
  showError;
  loading;


  email : string;
  password : string;
  username : string;

  verified: boolean;

  verifLoading: boolean = false;
  success: boolean = false;
  verifStatus: boolean = true;
  theVerifCounter = 0;
  counterExcess: boolean = true;
  mobile: Boolean = false;

  closeResult: any;

  verifText: String = "Have you mispelled your username? Please try again";
  successText: String = "Success, you have verified your eToro username";
  noVerifText: String = "You have not verified your username, please enter your username and click the magnifying glass to verify";
  noVerifStatus: boolean = false;

  constructor(private registerService : RegisterserviceService, private route: Router, private formBuilder: FormBuilder, private http: HttpClient, private modalService: NgbModal, public nav: NavbarService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: User Registration'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.registerform = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.minLength(6), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // window.history.replaceState(null, null, "/register");
    this.nav.hideSet(true);
    this.hide = true;

    // console.log(window.innerWidth)
    // console.log(window.innerHeight)
  }

  get f() { return this.registerform.controls; }

  error;

  register(){
   
    if(this.success){ 
      this.email = this.f.email.value;
      this.password = this.f.password.value;
      this.username = this.f.username.value;
      if(!this.password){
         this.error = true;
      }else{
        this.checkEmail();
      }
    }else{
      this.noVerifStatus = true;
      this.error = true;
    }
  }
  @ViewChild('content', { static: true }) content: any;
  async verify(){
    this.noVerifStatus = false;
    this.username = this.f.username.value;
    let theData: any;
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Info?', { headers }).subscribe(data => {
      // console.log(data);
      theData = data;
    })
    this.verifLoading = true;
    this.registerform.get('username').disable({ emitEvent: false });
    await new Promise(f => setTimeout(f, 1000));
    if(theData == null){
      // alert("Invalid you must have an eToro account")
      this.verifLoading = false;
      this.registerform.get('username').enable({ emitEvent: false });
      this.verifStatus = false;
      this.theVerifCounter++;
      if(this.theVerifCounter > 2){
        this.open(this.content);
        this.verifText = "Please ensure you have an active eToro account";
        this.counterExcess = false;
      }

    }else{
      // alert("SUCCESS")
      this.verifLoading = false;
      this.success = true;
      this.verifStatus = true;
    }


  }
  
  async checkEmail(){
    if(this.email == null ||this.email == "" || this.email.indexOf('@') == -1){
      // console.log("Invalid, you must use a valid email address.");
      this.error = "Invalid, you must use a valid email address.";
    }else{
      this.registerService.registration(this.email, this.password, this.username).subscribe(
        (response) => {
          // console.log(response);
          this.route.navigate(['/login']);
          const jsonData = JSON.stringify(this.email)
          localStorage.setItem('username', jsonData);
        }
      );

    }

    

  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  close(){
    this.modalService.dismissAll();
    this.route.navigate(['/academy']);
  }



}
