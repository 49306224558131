import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavbarService } from '../navbarservice.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-seo7',
  templateUrl: './seo7.component.html',
  styleUrls: ['./seo7.component.css']
})
export class Seo7Component implements OnInit, AfterViewInit {

  constructor(private meta: Meta, private nav: NavbarService, private _location: Location) { }
  mobile: Boolean = false;
  ngOnInit(): void {
    this.meta.addTags([
      {name: 'title', content: "Relative Momentum Ranking & Weight Allocation in Backseat Trader's Algorithm"},
      {name: 'description', content: "Explore the Backseat Trader algorithm's relative momentum ranking & weight allocation system, enhancing portfolio performance by prioritising high-potential assets."}
    ]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.nav.hideSet(true);
  }

  ngAfterViewInit(): void {

    let winPosSEO = sessionStorage.getItem("windowPosSubSEO");
    if(winPosSEO){
      // console.log("found!")
      let numWinPosSEO = Number(winPosSEO);
      // window.location.replace('http://localhost:4200/');
      if(numWinPosSEO > 0){
        // console.log("scrolling!", numWinPosBubbles)
        window.scrollTo(0, numWinPosSEO);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPosSEO){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPosSEO)
        }
      }
    }
  }

  backClicked() {
    this._location.back();
  }

  capturePos(){
    // console.log(window.scrollY, "scrollY")
    // console.log(window.screenY, "screenY")
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString())
  }

  @HostListener("window:beforeunload", ["$event"])
  refreshDetected(){
    // console.log("refreshed!");
    sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString());
  }

  removePos(){
    sessionStorage.removeItem("windowPos");
  }

}
