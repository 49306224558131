import { first, map } from 'rxjs/operators';
// import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../providers/config/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};

export const MAXFILES = 100;
declare var $: any;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Input() user: string;
  @Input() token: string;
  @Input() team: string;
  @Input() challenge: string;
  @Input() project: string;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  errorQuantity = '';
  errorSize = '';

  uploadform: FormGroup;
  fileToUpload: Array<File> = [];

  // Toast = Swal.mixin({
  //   toast: true,
  //   position: 'bottom',
  //   showConfirmButton: false,
  //   timer: 5000,
  //   timerProgressBar: true,
  //   didOpen: (toast) => {
  //     toast.addEventListener('mouseenter', Swal.stopTimer)
  //     toast.addEventListener('mouseleave', Swal.resumeTimer)
  //   }
  // })

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder, private configService: ConfigService, private http: HttpClient) {
  }

  // test = "http://localhost:8080/files";

  async ngOnInit() {
    this.uploadform = this.formBuilder.group({
      checked: [false, Validators.requiredTrue],
    });

    // return await this.http.post<any>(this.configService.url + 'multimedia/getAll',{})
    //   .pipe(first()).subscribe(data => {
    //     console.log(data)
    //     this.test+= data[0].media.location;
    //     console.log(this.test)
    //   })
  }

  fileChangeEvent(fileInput: any) {
    if (!fileInput) this.fileToUpload = [];
    else if (fileInput.target.files && fileInput.target.files.length < 100)
      for (let index = 0; index < fileInput.target.files.length; index++)
        this.fileToUpload.push(<File>fileInput.target.files[index]);
    this.checkFile();
  }

  removeItem(index: number) {
    this.fileToUpload.splice(index, 1);
    this.checkFile();
  }

  checkFile() {
    if (this.fileToUpload.length > MAXFILES) this.errorQuantity = 'Too many files';
    else this.errorQuantity = '';

    const isTooBig = this.fileToUpload.some(file => file.size > 500 * 1e+6);

    if (!isTooBig) this.errorSize = ''
    else this.errorSize = 'File exceeds the maximum upload size.'

    if (this.fileToUpload.length == 0) $(".fileinput").fileinput("clear");
  }

  get f() { return this.uploadform.controls; }

  async onSubmit() {

    // console.log("here")

    this.submitted = true;
    this.showError = false;
    this.success = false;

    // If there was an error in validation
    // if ((this.fileToUpload && this.fileToUpload.length == 0) || this.uploadform.invalid) return;
    // console.log("here")
    // Disable the button and show the spinner
    this.loading = true;


    const multimediaVm = {

    }

    let formData: any = new FormData();
    for (let index = 0; index < this.fileToUpload.length; index++)
      formData.append('file', this.fileToUpload[index]);

    Object.keys(multimediaVm).forEach(key => formData.append(key, multimediaVm[key]));

    return await this.http.post<any>(this.configService.url + 'multimedia/uploadMultimedia', formData)
      .pipe(first()).subscribe(data => {

        this.loading = false;
        this.submitted = false;
        this.success = true;

        setTimeout(() => {
          this.success = false;
        }, 15000);

        setTimeout(() => {
          this.activeModal.close('success')
        }, 3000);

      }, err => {
        this.showError = true;
        let error = 'An error has occurred.'
        if (err.error.message) error = err.error.message;
        // this.Toast.fire({
        //   icon: 'error',
        //   title: ' Your file(s) has(ve) not been uploaded. ' + error + ' Please Try Again.',
        // })
        this.loading = false;
        this.submitted = false;
      })


    // await (await this.configService.url + 'multimedia/uploadMultimedia', formData)
    //   .pipe(first()).subscribe(data => {
    //     this.loading = false;
    //     this.submitted = false;
    //     this.success = true;

    //     setTimeout(() => {
    //       this.success = false;
    //     }, 15000);

    //     setTimeout(() => {
    //       this.activeModal.close('success')
    //     }, 3000);

    //   }, err => {
    //     this.showError = true;
    //     let error = 'An error has occurred.'
    //     if (err.error.message) error = err.error.message;
    //     // this.Toast.fire({
    //     //   icon: 'error',
    //     //   title: ' Your file(s) has(ve) not been uploaded. ' + error + ' Please Try Again.',
    //     // })
    //     this.loading = false;
    //     this.submitted = false;
    //   })
  }
}

