import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { map, first } from "rxjs/operators";

import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-etoro',
  templateUrl: './etoro.component.html',
  styleUrls: ['./etoro.component.css']
})
export class EtoroComponent implements OnInit {

  closeResult = '';
  yourName;
  yourEmail;
  friendsName;
  friendsEmail;

  constructor(private modalService: NgbModal, private http: HttpClient, private config : ConfigService) { }

  ngOnInit(): void {
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //post goes here
      this.checkEmail(this.friendsEmail);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async checkEmail(email){
    if(email == null ||email == "" || email.indexOf('@') == -1){
      alert("Invalid, you must use a valid email address.");
    }else{
      // console.log("success");
      
      var url = this.config.url; 

      this.http.post<any>(url + 'user/refer', {yourNameRF: this.yourName, yourEmailRF: this.yourEmail, friendsNameRF: this.friendsName, friendsEmailRF: this.friendsEmail}).pipe(first()).subscribe(data => {
        // console.log('register sucess');
      }, err => {
        // console.log('error in register');
      })

    }

    

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
