import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './providers/config/config.service';
import { map, first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SigninserviceService {

  email;
  password;

  setParams(checkEmail : string, checkPassword : string): Observable<any>{
    try{
    this.email = checkEmail;
    this.password = checkPassword;

    // console.log("service",this.email);
    // console.log("service",this.password);
    return this.http.post<any>(this.config.url + 'user/login', {email: this.email, password: this.password})
    .pipe(map(user => {
      return user;
    }));
    }catch(exception){
      // console.log(exception);
    }

  }

  constructor(private http: HttpClient, private config: ConfigService) { }
}
