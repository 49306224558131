import { AfterContentChecked, Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit, AfterContentChecked {
  title = 'our Etoro Portfolio!';
  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  finalGains = [];
  finalMonthly = [];

  mobile: Boolean = false;

  // theReload: boolean = true;
  public options: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  constructor(
    private http: HttpClient, public nav: NavbarService, private meta: Meta
  ) { }

  ngOnInit() {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: Live Charts and Updates'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);
    //Highcharts.chart('container', this.options);
    // console.log(this.finalGains);
    this.username = "cosmicetoro";
    this.submit();
    // if(this.theReload = true){}
    // this.loadFeed();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }

    // window.history.replaceState(null, null, "/etoro");
    this.nav.hideSet(true);

  }

  @ViewChild('screen', { static: false }) screenElement: ElementRef;
  @ViewChild('risk', { static: false }) screenElementRisk: ElementRef;
  @ViewChild('copiers', { static: false }) screenElementCopiers: ElementRef;
  private scrolling = false;
  private scrollingTimer: any;

  @HostListener('document:scroll', ['$event'])
  onScroll(event): void {
    // this.scrolling = true;
    if(!this.scrolling){
      this.scrollingTimer = setTimeout(() => {
        this.scrolling = true;
      }, 500);
    }
    // this.checkScroll();
    this.handleScroll(this.screenElement.nativeElement);
    this.handleScroll(this.screenElementRisk.nativeElement);
    this.handleScroll(this.screenElementCopiers.nativeElement);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.scrolling = false;
    // console.log("keyed!")
    // console.log(this.scrolling)
     // Adjust the timeout duration based on your needs
  }

  private handleScroll(element: HTMLElement): void {
    // console.log(this.scrolling)
    if (this.scrolling && !this.mobile) {
      const rect = element.getBoundingClientRect();
      const marginAdjustment = 0.07;
      const middleOfScreen = window.innerHeight / 2;
      const middleOfElement = rect.height / 2;

      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        const margin = middleOfScreen - middleOfElement + marginAdjustment * window.innerHeight;
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // Center the middle of the element
          inline: 'center', // Apply the calculated margin
        });
      }
    }
  }


removed: boolean = false;
  ngAfterContentChecked(): void {
    if(this.removed == false){
      if(document.querySelector('a[href*="https://elfsight.com"]')){
        document.querySelector('a[href*="https://elfsight.com"]').remove();
        this.removed = true;
      }
    }
  }

  // async loadFeed() {
  //   // await new Promise(f => setTimeout(f, 1000));
  //   let x = document.getElementById("theFeed");

  //   let y = "<iframe src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbackseattrader%2F&tabs=timeline&width=340&height=331&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId' width='500' height='740' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>"
  //   x.innerHTML = y;
  // }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }

  async submit() {
    this.riskAvg = 0;
    this.instruments = [];
    this.instrumentIds = [];
    this.instrumentIdVal = [];

    this.riskId = [];
    this.namesRequest = [];
    this.nameUrl = "";

    this.nameSet = [];
    this.nameVals = [];

    this.riskNames = [];
    //console.log(this.username);
    await this.getMonthlyGains();
    await this.getCopiers();
    await this.getLiveRiskScore();
    await this.getLivePortfolio();
    await this.getDiscovery();
    await this.getPerf();
    await this.getRisk();

  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.populateGraphWithDynamicValues();
      this.monthlyGain = [];
      this.parsedMonthly = [];
      this.gainsMonthly = [];
      this.timestampMonthly = [];
    })
  }

  populateGraphWithDynamicValues() {
    var dateArr = [];
    var date;

    for (var i = 0; i <= this.timestampMonthly.length; i++) {
      date = new Date(this.timestampMonthly[i]);
      dateArr.push(this.convertDate(date))
      // console.log(dateArr[i]);
    }
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      xAxis: {
        categories: dateArr
      },
      title: {
        text: 'Monthly Gains',
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 60,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },

      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly
      }]

    }
    Highcharts.chart('container', this.options);
  }

  instruments = [];
  instrumentIds = [];
  instrumentIdVal = [];

  riskId = [];
  namesRequest = [];
  nameUrl = "";

  nameSet = [];
  nameVals = [];

  riskNames = [];
  portNames = [];

  async getLiveRiskScore() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/Live/', { headers }).subscribe(data => {
      this.instruments = data;
      //console.log(this.instruments);
      this.instrumentIds = this.instruments["contributers"];
      //console.log("this.instrumentIds - user set",this.instrumentIds);
      // this.instrumentIds has all details for risk
      this.instrumentIds.forEach(i => {
        this.instrumentIdVal.push(i['instrumentId']);
      });

      for (var i = 0; i <= this.instrumentIdVal.length - 1; i++) {
        if (i == this.instrumentIdVal.length - 1) {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i]);
        } else {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i] + "&");
        }
      }

      this.nameUrl = this.namesRequest.toString();
      var reqUrl = this.nameUrl.replace(/,/g, '');


      //console.log(reqUrl);

      const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
      this.http.get<any>('https://api.etoro.com/Metadata/V1/Instruments?' + reqUrl, { headers }).subscribe(data => {
        // console.log("metadata set",data)
        this.nameSet = data;
        this.nameSet.forEach(i => {
          this.nameVals.push(i["name"]);
        })
        // console.log("names from metadata", this.nameVals);
        this.nameSet.forEach(i => {
          this.riskId.push(i["instrumentId"]);
        })

        this.riskId.sort((a, b) => a - b);
        this.instrumentIdVal.sort((a, b) => a - b);
        this.instrumentIds.sort((a, b) => a - b)
        //console.log(this.instrumentIdVal);

        for (var i = 0; i <= this.riskId.length - 1; i++) {
          //console.log("test")
          this.instrumentIds[i].instrumentId = this.nameSet[i].name;
          this.portfolioPositions[i].positionId = this.nameVals[i];
        }

      });



      // console.log("this.riskId - metadata set",this.riskId);
      // console.log("instrumentIdVal - user set", this.instrumentIdVal);
      // console.log(this.riskNames);



    })

  }

  portfolioSet = [];
  portfolioPositions = [];
  async getLivePortfolio() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Portfolio/Live', { headers }).subscribe(data => {

      // console.log(data);
      this.portfolioSet = data;
      //console.log(this.portfolioSet)
      this.portfolioPositions = this.portfolioSet["positions"];

      this.portfolioPositions.sort((a, b) => a - b);

      // console.log(this.portfolioPositions);
      //var test = this.nameVals;
      // console.log(test);
      //var max = this.portfolioPositions.length;
      // console.log(max);
      // this.portfolioPositions[1].positionId = "hello";
      // console.log(this.portfolioPositions[1].positionId);
      // for(var i = 0; i<=max - 1; i++){
      // console.log("testing")
      // this.portfolioPositions[i].positionId = this.nameVals[i];
      // }
    })
  }

  discoveryuserName;
  discoveryweeksSinceRegistration;
  discoverygain;
  discoverydailyGain;
  discoverythisWeekGain;
  discoveryriskScore;
  discoverycopiers;
  discoverycopiersGain;
  discoverytrades;
  discoverywinRatio;
  discoverydailyDD;
  discoveryweeklyDD;
  discoverypeakToValley;
  discoveryprofitableWeeksPct;
  discoveryprofitableMonthsPct;
  discoveryavgPosSize;
  async getDiscovery() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Discovery?Period=LastYear', { headers }).subscribe(data => {

      this.discoveryuserName = data.userName;
      this.discoveryweeksSinceRegistration = data.weeksSinceRegistration;
      this.discoverygain = data.gain;
      this.discoverydailyGain = data.dailyGain;
      this.discoverythisWeekGain = data.thisWeekGain;
      this.discoveryriskScore = data.riskScore;
      this.discoverycopiers = data.copiers;
      this.discoverycopiersGain = data.copiersGain;
      this.discoverytrades = data.trades;
      this.discoverywinRatio = data.winRatio;
      this.discoverydailyDD = data.dailyDD;
      this.discoveryweeklyDD = data.weeklyDD;
      this.discoverypeakToValley = data.peakToValley;
      this.discoveryprofitableWeeksPct = data.profitableWeeksPct;
      this.discoveryprofitableMonthsPct = data.profitableMonthsPct;
      this.discoveryavgPosSize = data.avgPosSize;
    })
  }

  public perfOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  perfGain = [];
  parsedPerf = [];
  perfMonthly = [];
  perfTime = [];

  async getPerf() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.perfGain = data;
      this.parsedPerf = this.perfGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedPerf.forEach(i => {
        this.perfMonthly.push(i['gain']);
        this.perfTime.push(i['timestamp']);
      });
      this.populatePerf();
      //console.log(this.perfMonthly)
      this.perfGain = [];
      this.parsedPerf = [];
      this.perfMonthly = [];
      this.perfTime = [];
    })
  }

  populatePerf() {
    var dateArr = [];
    var date;

    for (var i = 0; i <= this.perfTime.length; i++) {
      date = new Date(this.perfTime[i]);
      dateArr.push(this.convertDate(date))
      // console.log(dateArr[i]);
    }
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: dateArr
      },
      title: {
        text: 'Performance',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        }
      },
      series: [{
        name: 'Monthly Gains',
        data: this.perfMonthly
      }]

    }
    Highcharts.chart('containerPerf', this.options);
  }

  public riskOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  riskSet = [];
  parsedRisk = [];
  riskValues = [];
  riskTimes = [];
  riskAvg = 0;
  riskText;

  async getRisk() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/History', { headers }).subscribe(data => {
      //console.log('data', data);
      this.riskSet = data;
      // console.log(this.riskSet)
      this.parsedRisk = this.riskSet["riskScore"];

      this.riskSet.forEach(i => {
        this.riskValues.push(i['riskScore']);
        this.riskAvg = this.riskAvg + i['riskScore'];
        this.riskTimes.push(i['timestamp']);
      });
      // console.log(this.riskValues);
      // console.log(this.riskTimes);

      this.riskAvg = this.riskAvg / this.riskValues.length;
      let buff = this.riskAvg.toString(); //If it's not already a String
      buff = buff.slice(0, (buff.indexOf(".")) + 3);
      this.riskText = buff;
      //console.log(this.riskAvg);

      this.populateRisk();
      //console.log(this.perfMonthly)
      this.riskSet = [];
      this.parsedRisk = [];
      this.riskValues = [];
      this.riskTimes = [];
    })
  }

  populateRisk() {
    var dateArr = [];
    var date;

    for (var i = 0; i <= this.riskTimes.length; i++) {
      date = new Date(this.riskTimes[i]);
      dateArr.push(this.convertDate(date))
      // console.log(dateArr[i]);
    }

    let max = 0;
    for (let i = 0; i <= this.riskValues.length - 1; i++) {
      if (max <= this.riskValues[i]) {
        max = this.riskValues[i];
      }
    }

    let start = 0;
    let end = dateArr.length - 2;
    let mid = Math.round((start+end)/2);

    let distance = end - mid; 
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      yAxis: {
        min: 2
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      title: {
        text: '',
      },
      plotOptions: {
        column: {
          zones: [{
            value: max, // Values up to 10 (not including) ...
            color: '#A5FF70' // ... have the color blue.
          }, {
            color: '#FFD754' // Values from 10 (including) and up have the color red
          }]
        }
      },
      series: [{
        name: 'Risk Score',
        data: this.riskValues
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    Highcharts.chart('containerRisk', this.options);
  }

  public copiersOptions: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  copiersSet = [];
  copiersParsed = [];
  recentCopiers = 0;
  copiersTime = [];

  async getCopiers() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Copiers/History?MinDate=2021-11-24', { headers }).subscribe(data => {

      //console.log(data);
      this.copiersSet = data;
      this.copiersSet.forEach(i => {
        this.copiersParsed.push(i['copiers']);
        this.copiersTime.push(i['timestamp']);
      })
      //console.log(this.copiersParsed);
      var x = this.copiersParsed.length - 1;
      this.recentCopiers = this.copiersParsed[x];
      this.populateCopiers();
      //console.log(this.recentCopiers);
    })
  }

  populateCopiers() {
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    let max = 0;
    for(let i = 0; i<=this.copiersParsed.length; i++){
      if(this.copiersParsed[i] > max){
        max = this.copiersParsed[i];
      }
    }
    this.options = {
      chart: {
        type: 'area'
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]},
      yAxis: {
        min: max / 2,
        max: max
      },
      xAxis: {
        categories: 0,
        labels: {
          enabled: false,
        }
      },
      title: {
        text: '',
      },
      series: [{
        name: 'Copiers',
        data: this.copiersParsed
      }]

    }
    Highcharts.chart('copiersContainer', this.options);
  }



}
