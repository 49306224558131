import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';

import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.css']
})
export class AcademyComponent implements OnInit{

  closeResult: any;
  mobile: Boolean = false;

  constructor(private modalService: NgbModal, private router: Router, public nav: NavbarService, private meta: Meta) { 
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // };
  }

  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: Video Academy'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }
    // window.history.replaceState(null, null, "/#/academy");
    // window.history.replaceState(null, null, "/academy");
    // window.history.pushState({page: "another"}, "another page", "example.html");
    // window.onbeforeunload = () => {this.ngOnDestroy();};
  //   window.addEventListener("beforeunload", function (e) {
  //     this.reloadPage();             // Gecko, WebKit, Chrome <34
  // });
//   window.addEventListener("keyup", disableF5);
//   window.addEventListener("keydown", disableF5);

//  function disableF5(e) {
//     if ((e.which || e.keyCode) == 116) e.preventDefault(); 
//  };
    this.nav.hideSet(true);
  }

//   @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
//         this.router.navigate(['/#/academy'])
//         event.preventDefault();
//     }
// reloadPage(){

// }
//   async ngOnDestroy() {   
//     this.router.navigateByUrl('/user');

    
//   }

//   ngAfterViewInit(): void {
//     this.theTimer();
//   }

  async theTimer(){
    await new Promise(f => setTimeout(f, 2000));
    window.history.replaceState({page: window.location.href}, null, "/academy");

  }

//   @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
//     console.log("Processing beforeunload...");
    
//     window.history.replaceState(null, null, "/#/academy");
 


//     // window.history.pushState({page: "another"}, "another page", "/#/academy");
// }

open(content) {
  // console.log(content)
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    },
  );
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

close(video){
  // document.getElementById(video);
  // console.log(video)
  video.pause();
}


}
