import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../navbarservice.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../providers/config/config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Meta } from '@angular/platform-browser';

declare global {
  interface Window { gtag: any}  
}

@Component({
  selector: 'app-joinusintermediate',
  templateUrl: './joinusintermediate.component.html',
  styleUrls: ['./joinusintermediate.component.css']
})


export class JoinusintermediateComponent implements OnInit {

  constructor(private nav: NavbarService, private modalService: NgbModal, private config: ConfigService, private http: HttpClient, private meta: Meta) { }

  mobile: Boolean = false;
  subscribeEmail: String;
  

  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: "Backseat Trader - Join us on eToro"},
      { name: 'description', content: "Discover Backseat Trader's Top 20 feature, driven by advanced analytics, for diversified investing and leveraging experienced traders in a social trading environment." }
    ]);

    this.nav.hideSet(true);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  send(){
    this.checkEmail(this.subscribeEmail);
  }

  fail;
  subText;
  show;

  async checkEmail(email) {
    if (email == null || email == "" || email.indexOf('@') == -1) {
      // console.log("Invalid, you must use a valid email address.");
      this.fail = true;
      this.subText = "Invalid, you must use a valid email address."
      setTimeout(() => (this.fail = false), 3000);
    } else {
      // console.log("success");
      // console.log(email);
      
      var url = this.config.url;
      
      await this.http.post<any>(url + 'reminder/getReminder', { email: email }).subscribe(data => {
        // console.log('register sucess');
        this.show = true;
        this.subText = "Success, you will receive your reminder in 10 days"
        setTimeout(() => (this.show = false), 3000);
 
      }, err => {
        // console.log('error in register');
      })

    }
  }

  
  gtag_report_conversion(url) {

    // console.log(url)
    // alert("here")
    
    var gtag = window.gtag;
    
    var callback = function () {
  
      if (typeof(url) != 'undefined') {
  
        window.location = url;
  
      }
  
    };
  
    window.gtag('event', 'conversion', {
  
        'send_to': 'AW-11230383227/X0IuCLqW6M8YEPuYiOsp',
  
        'event_callback': callback
  
    });
  
    return false;
  
  }


}
