import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NavbarService } from 'src/app/navbarservice.service';
//import { appendFile } from 'fs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    public mobile = false;
    currentRoute: string;

    constructor(public location: Location, private router: Router, public nav: NavbarService) {
       
    }

    ngOnInit() {
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });

     window.onresize = () => this.mobile = window.innerWidth <= 991;

     if (window.screen.width === 360) { // 768px portrait
        this.mobile = true;
        let element = document.getElementById('mobileCheck');
        element.className = "sticky";
      }
    }

    checkRoute(){
        // console.log(this.router.url)
    }

    // routeLive(){
    //     this.router.navigateByUrl('/etoro')
    //     .then(() => {
    //       window.location.reload();
    //     });
    // }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    
    async loginCheck(){
        let username = await localStorage.getItem('username');

        if(username){
            this.router.navigateByUrl('/user');
    
        }else{
            this.router.navigateByUrl('/login');
            
        }
    }

    removePos(){
        sessionStorage.removeItem("windowPos");
    }

    changePrivacySettings(event: Event): void {
        event.preventDefault();  // Prevent the default action (e.g., following the link)
        if (window['__lxG__consent__'] !== undefined && window['__lxG__consent__'].getState() !== null) {
          window['__lxG__consent__'].showConsent();
        } else {
          alert('This function only for users from European Economic Area (EEA)');
        }
      }
}
